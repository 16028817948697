import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import './basics.module.css'

const IndexPage = () => {
    return (
        <Layout></Layout>
    )
}

export default IndexPage;