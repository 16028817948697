import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import style from './pageTitle.module.css'
import { useEffect } from "react";
import { useState } from "react";
import TabLink from "../tab-link/tabLink";

function compareNombres(a, b) {
    return a.doc.node.frontmatter.sort - b.doc.node.frontmatter.sort
}

const PageTitle = ({ currentTitle, updatePage, mdxId }) => {
    const [mdx, setMdx] = useState()
    const [docs, setDocs] = useState();

    const data = useStaticQuery(graphql`{
        allBlogs: allMdx(sort: { order: ASC, fields: [frontmatter___title] }) {
          edges {
            node {
              id
              excerpt(pruneLength: 250)
              frontmatter {
                slug
                title
                tab
                category
              }
            }
          }
        }
    }`);

    useEffect(() => {
        setDocs(currentTitle.docs.sort(compareNombres));
        setMdx(data.allBlogs.edges.find((item) => item.node.id === mdxId))
    }, [data, mdxId])

    const checkIsActive = (id) => {
        return id === mdxId
    }

    if (mdx) {
        return (
            <div className={style.pageTitleContainer + " " + style.noSelect}>
                <p className={style.categoryTitle}>{mdx.node.frontmatter.category}</p>
                <div className={style.whiteLine}></div>
                <h1 className={style.PageTitle}>{currentTitle.subTitle}</h1>
                <div className={style.tabContainer}>
                    { docs.map((doc) => {
                        if (checkIsActive(doc.doc.node.id))
                            return <TabLink key={doc.doc.node.id} tab={doc.doc} isActive={true} updatePage={updatePage}></TabLink>
                        else
                            return <TabLink key={doc.doc.node.id} tab={doc.doc} isActive={false} updatePage={updatePage}></TabLink>
                    }
                    )}
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}

export default PageTitle;