import React from "react";
import style from './sidebarLink.module.css';

function compareNombres(a, b) {
    return a.doc.node.frontmatter.sort - b.doc.node.frontmatter.sort
}

const SidebarLink = ({ item, isActive, updatePage, setCurrentTitle }) => {
    const performUpdate = () => {
        const sorted = item.docs.sort(compareNombres)
        updatePage(sorted[0].doc.node.id)
    }

    return (
        <div className={style.linkContainer + " " + (isActive ? style.linkActive : "")} onClick={() => performUpdate()}>
            <p className={style.linkText}>{item.subTitle}</p>
        </div>
    )
}

export default SidebarLink;