import React, { useState } from "react";
import style from './sidebarDropdown.module.css'
import SidebarLink from '../sidebar-link/sidebarLink';

const SidebarDropdown = ({ category, updatePage, activePage, setCurrentTitle }) => {
    const [isOpen, setIsOpen] = useState(true);

    const checkIsActive = (item, active) => {
        return item.docs.find((doc) => doc.doc.node.id === active) ? true : false;
    }

    if (isOpen) {
        return (<>
            <div className={style.categoryTitleContainer} onClick={() => { setIsOpen(false) }}>
                <p className={style.categoryTitle}>{category.category}</p>
                <div className={style.categoryIconContainer}>
                    <img className={style.categoryIcon} src="/icons/arrow-down.png" alt="arrow-down" />
                </div>
            </div>
            { category.items.map((item) => {
                if (checkIsActive(item, activePage))
                    return <SidebarLink updatePage={updatePage} key={item.docs[0].id} item={item} isActive={true} setCurrentTitle={setCurrentTitle}></SidebarLink>
                else
                    return <SidebarLink updatePage={updatePage} key={item.docs[0].id} item={item} isActive={false} setCurrentTitle={setCurrentTitle}></SidebarLink>
            })}
        </>)
    } else {
        return (<>
            <div className={style.categoryTitleContainer} onClick={() => { setIsOpen(true) }}>
                <p className={style.categoryTitle}>{category.category}</p>
                <div className={style.categoryIconContainer}>
                    <img className={style.categoryIcon} src="/icons/arrow-up.png" alt="arrow-up" />
                </div>
            </div>
        </>)
    }
}

export default SidebarDropdown;