import React from "react";
import style from './sidebar.module.css';
import SidebarDropdown from '../sidebar-dropdown/sidebarDropdown';


const Sidebar = ({ updatePage, activePage, categories, setCurrentTitle }) => {
    return (
        <div className={style.sidebarContainer + " " + style.noSelect}>
            <div className={style.logoContainer}>
                LOGO
            </div>
            <div className={style.searchContainer}>
                <img className={style.searchIcon} src="/icons/search.png" />
                <input className={style.searchInput} type="text" placeholder="Search"></input>
            </div>
            {
                categories.map((item) => {
                    return <SidebarDropdown category={item} key={item.id} updatePage={updatePage} activePage={activePage} setCurrentTitle={setCurrentTitle}></SidebarDropdown>
                })
            }
        </div>
    )
};

export default Sidebar;