import React from "react"
import style from './tabLink.module.css'

const TabLink = ({ tab, isActive, updatePage }) => {
    return (
        <div className={style.tabContainer + " " + (isActive ? style.tabActive : "")} onClick={() => updatePage(tab.node.id)}>
            <p className={style.tabTitle}>{tab.node.frontmatter.tab}</p>
        </div>
    )
}

export default TabLink;