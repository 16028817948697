import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Sidebar from "../sidebar/sidebar";
import PageTitle from "../page-title/pageTitle";
import PageRenderer from "../page-renderer/pageRenderer";
import style from "./layout.module.css";
import { useEffect } from "react";

function onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

function compareTitleSort(a, b) {
    return a.docs[0].doc.node.frontmatter.titleSort - b.docs[0].doc.node.frontmatter.titleSort
}

function compareCategorySort(a, b) {
    return a.items[0].docs[0].doc.node.frontmatter.categorySort - b.items[0].docs[0].doc.node.frontmatter.categorySort;
}

const Layout = () => {
    const [mdxId, setMdxId] = useState();
    const [currentTitle, setCurrentTitle] = useState();

    const data = useStaticQuery(graphql`{
        allBlogs: allMdx(sort: { order: ASC, fields: [frontmatter___title] }) {
          edges {
            node {
              id
              excerpt(pruneLength: 250)
              frontmatter {
                slug
                title
                tab
                category
                sort
                categorySort
                titleSort
              }
            }
          }
        }
        defaultDoc: allMdx(filter: {frontmatter: {title: {eq: "Welcome"}}}) {
            edges {
                node {
                    id
                }
            }
        }
    }`);

    const updatePage = (id) => {
        setMdxId(id);
        const categories = postsGroupedByCategory();
        let item = {};
        categories.forEach((category) => {
            category.items.forEach((cItem) => {
                if (cItem.docs.find((doc) => doc.doc.node.id === id) ? true : false)
                    item = cItem;
            })
        })
        setCurrentTitle(item);
    }

    useEffect(() => {
        updatePage(data.defaultDoc.edges[0].node.id);
    }, [])
    
    let uniqueCategories = data.allBlogs.edges.map(edge => edge.node.frontmatter.category).filter(onlyUnique);

    const postsGroupedByCategory = () => {
        const arr = [];
        let arr2 = []; // { subTitle: '', docs: [] }
        for (let i = 0; i < uniqueCategories.length; i++) {
            let categoryItems = data.allBlogs.edges.filter((edge) => edge.node.frontmatter.category === uniqueCategories[i]);
            for (let j = 0; j < categoryItems.length; j++) {
                const found = arr2.find((itemArr) => itemArr.subTitle === categoryItems[j].node.frontmatter.title)
                if (!found) {
                    arr2.push({ subTitle: categoryItems[j].node.frontmatter.title, docs: [{ doc: categoryItems[j], id: j}]})
                }
                else
                    found.docs.push({ doc: categoryItems[j], id: j});
                
            }
            arr2.sort(compareTitleSort)
            arr.push({ category: uniqueCategories[i], items: arr2, id: i});
            arr2 = [];
        }
        arr.sort(compareCategorySort)
        return arr;
    };
    
    if (mdxId) {
        return (
            <>
                <div className={style.container + " " + style.noSelect}>
                    <div className={style.sidebarContainer}>
                        <Sidebar updatePage={(mdx) => updatePage(mdx)} activePage={mdxId} categories={postsGroupedByCategory()} setCurrentTitle={setCurrentTitle}></Sidebar>
                    </div>
                    <div className={style.contentContainer}>
                        <PageTitle mdxId={mdxId} currentTitle={currentTitle} updatePage={(mdx) => updatePage(mdx)}></PageTitle>
                        <PageRenderer mdxId={mdxId}></PageRenderer>
                    </div>
                </div>
            </>
        )
    } else {
        return (<></>)
    }
}

export default Layout;