import React, { useState, useEffect } from "react";
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link, useStaticQuery, graphql } from "gatsby"
import style from './pageRenderer.module.css'
import ListIcon from "../ListIcon"

const shortcodes = { Link, ListIcon }

const PageRenderer = ({ mdxId }) => {
    const [mdx, setMdx] = useState();

    const data = useStaticQuery(graphql`{
        allBlogs: allMdx(sort: {order: ASC, fields: [frontmatter___title]}) {
            edges {
                node {
                id
                body
                }
            }
        }
      }`);

    useEffect(() => {
        setMdx(data.allBlogs.edges.filter((edge) => edge.node.id === mdxId)[0].node);
    }, [data, mdxId])

    if (mdx) {
        return (<>
            <div className={style.mdxContainer}>
                <MDXProvider components={shortcodes}>
                    <MDXRenderer>{mdx.body}</MDXRenderer>
                </MDXProvider>
            </div>
        </>)
    } else {
        return (<></>)
    }
}

export default PageRenderer;